import PageHeader from '@/components/PageHeader.vue'
import $userRequest from '@/request/contactsapi.js'
import $contractreq from '@/request/contractApi.js'
import contractFilsList from '@/views/contract/contractFilsList.vue'
import root from '@/minxin/root'
import addUser from '@/components/addUser.vue'
const salesContract = {
  name: 'salesContract',
  components: {
    PageHeader,
    contractFilsList,
    addUser
  },
  mixins: [root],
  data() {
    return {
      root_name: '',
      root_msg: '',
      titletext: '销售合约',
      createContracttitle: '',
      placeholdertext: '合约编号  合约名称  甲方  乙方  送达方',
      contractType: 2, // 合约类型 -1-全部；1-作为买方的合约；2-作为卖方的合约
      listParas: {
        IDX: -1,
        KeyWords: '', // 输入的查找关键字
        StartTime: '', // 创建开始时间
        EndTime: '', // 创建结束时间
        CreateTime: '',
        contractType: -1, // 合约类型 -1-全部；1-作为买方的合约；2-作为卖方的合约
        ContractName: '', // 合约名称
        ContractCode: '', // 销售合约编号
        BuyCompanyID: null, // 买方id
        SellCompanyID: null, // 供应商
        CustomerCompanyID: null, // 终端
        CreateUserID: null, // 创建人
        CreateUserName: '', // 创建人
        Status: null, // 状态id
        IsAdvancedSearch: 0, // 是否高级搜索 1是，0否
        PageIndex: 1,
        PageSize: 20
      },
      tableTotalSize: 0, // 合约总行数
      // 合约列表
      tableData: [
        // {
        //   IDX: 1,
        //   ContractNo: '312312',
        //   Name: 'fsadfsadfs',
        //   BuyCompanyName: 'fdsaf',
        //   SellCompanyName: 'fasfdsf',
        //   SendCompanyName: 'fasfdsf',
        //   NickName: 'ffdsa',
        //   StatusName: '已启用',
        //   Status: 1,
        //   DeadLine: '2021-09-12',
        //   WriteTime: '2020-02-03'
        // }
      ],
      tableHeight: 'calc(100vh - 340px)',
      tableDataloading: false,
      // 合约高级搜索
      AdvancedsearchVisibel: false,
      // 状态筛选
      statusList: [
        {
          lable: '已启用',
          value: 1
        },
        {
          lable: '已禁用',
          value: 2
        },
        {
          lable: '待审核',
          value: 3
        },
        {
          lable: '已驳回',
          value: 4
        }
      ],
      // 买方list
      BuyCompanyList: [
      ],
      // 供应商list
      SaleCompanyList: [
      ],
      // 终端list
      CustomerCompanyList: [
      ],
      allbuyCompanyList: [],
      allSaleCompanyList: [],
      allCustomerCompanyList: [],
      // 新建合约
      createcontractVisibel: false,
      contractInfo: {
        Name: '',
        BuyCompanyID: null,
        SellCompanyID: 1974,
        SendCompanyID: null,
        DeadLine: '',
        UserName: '',
        NickName: '',
        ContractType: null, // 合约类型 // 0 销售， 1采购
        Operater: null
      },
      createContractrules: {
        ContractName: [{ required: true, message: '请填写合约名称', trigger: 'blur' }],
        BuyCompanyID: [
          { required: true, message: '请选择买方', trigger: 'blur' },
          { required: true, message: '请选择买方', trigger: 'change' }
        ],
        SellCompanyID: [
          { required: true, message: '请选择供应商', trigger: 'blur' },
          { required: true, message: '请选择供应商', trigger: 'change' }
        ],
        CustomerCompanyID: [
          { required: true, message: '请选择终端', trigger: 'blur' },
          { required: true, message: '请选择终端', trigger: 'change' }
        ],
        DeadLine: [
          { required: true, message: '请选择失效时间', trigger: 'blur' },
          { required: true, message: '请选择失效时间', trigger: 'change' }
        ]
      },
      // 合约附件
      contractFilesVisibel: false,
      contractFileList: [
        {
          idx: 1,
          fileUrl: 'ffsafsda',
          filename: 'test001',
          uploadUser: 'phalange',
          remark: 'text',
          editTime: '2021-03-06'
        }
      ],
      contractIdforfile: null, // 合约id
      // ================================================= 选择人员 begin  =================================================
      // ================================================= 选择人员 begin ==================================================
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员tab切换
      isshow: false, // 控制 部门和角色选择是否显示
      selectUserkeywords: '', // 搜索成员关键字
      connuserlist: [], // 成员list
      fullscreenLoading: false,
      selectUserDeptData: [], // 部门数据
      selectUserroleData: [], // 角色数据
      checkedUserInfo: null, // 选中的人员对象
      checkedContractOptionUserID: null, // 选中人员的id
      checkedContractOptionUserName: null, // 选中人员的name
      checkedContractOptionUserNickName: null, // 选中人员的NickName
      // ================================================= 选择人员 end  =================================================
      // ================================================= 选择人员 end ==================================================
      // 获取公司列表参数
      sellCompanyListParas: {
        CompanyType: null, //  0、客户；1、供应商
        CompanyName: '', // 关键字
        pageIndex: 1,
        pageSize: -1
      },
      userInfo: null,
      isEditSell: true,
      isEditBuy: true,
      isCanCreate: true, // 是否可创建新合约
      selUserType: null, // 1：列表选择业务员 2 创建合约选择业务员
      checkedContract: [], // 选中的合约数据
      // 选择甲方
      selectBuyloading: false,
      // 选择供应商
      selectsellloading: false,
      // 选择终端
      selectsendloading: false
    }
  }, // data ends
  watch: {
    selectUserkeywords(val) {
      this.$refs.allusertree.filter(val)
    }
  },
  created() {
    const _contractType = this.$route.meta.contractType
    if (_contractType === 1) {
      this.root_name = 'cghy'
      this.root_msg = '采购合约'
    } else {
      this.root_name = 'xshy'
      this.root_msg = '销售合约'
    }
  },
  mounted() {
    // 获取合约类型 ， 如果没有则默认 销售合约
    const _contractType = this.$route.meta.contractType
    if (this.$route.params.from === 'Guidepage') {
      $userRequest.GetUserInfo().then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          this.userInfo = res.Message
          this.isCanCreate = false
          this.oprenCreateContract()
        }
      })
    }
    this.contractType = _contractType
    if (_contractType === 1) {
      this.titletext = '采购合约'
      this.placeholdertext = '可通过合约编号、合约名称、甲方、乙方搜索'
      this.createContracttitle = '创建采购合约'
      // 获取按钮权限
      // this.getBottons('cghy')
    } else {
      this.titletext = '销售合约'
      this.placeholdertext = '可通过合约编号、合约名称、甲方、乙方、送达方搜索'
      this.createContracttitle = '创建销售合约'
      // 获取按钮权限
      this.root_name = 'xshy'
      this.root_msg = '销售合约'
      // this.getBottons('xshy')
    }
    if (this.$route.params.from !== 'Guidepage') {
      this.getUserInfo()
    }
    this.getContractList()
    this.getSellCompanyList()
    this.getBuyCompanyListfromdata()
  },
  methods: {
    // 获取页面按钮权限
    // async getBottons(elementId) {
    //   const _this = this
    //   await this.$jurisdiction.GetUserButtonListByMenuElementID({ elementId: elementId }).then(res => {
    //     if (res.RetCode === '0') {
    //       _this.userBottons = res.Message
    //     } else {
    //       _this.$message.error('加载页面出错： ' + res.Message)
    //       console.log(this.titletext + '列表获取按钮权限报错：' + res.RetMsg)
    //     }
    //   })
    // },
    // 初始化合约搜索条件
    initadvance() {
      this.listParas = {
        IDX: -1,
        KeyWords: '', // 输入的查找关键字
        StartTime: '', // 创建开始时间
        EndTime: '', // 创建结束时间
        CreateTime: '',
        contractType: -1, // 合约类型 -1-全部；1-作为买方的合约；2-作为卖方的合约
        ContractName: '', // 合约名称
        ContractCode: '', // 销售合约编号
        BuyCompanyID: null, // 买方id
        SellCompanyID: null, // 供应商
        CustomerCompanyID: null, // 终端
        UserID: null, // 创建人
        UserName: '', // 创建人
        Status: null, // 状态id
        IsAdvancedSearch: 0, // 是否高级搜索 1是，0否
        PageIndex: 1,
        PageSize: 20
      }
    },
    // 打开高级搜索
    openadvanceContrac() {
      this.listParas.IsAdvancedSearch = 1
      this.AdvancedsearchVisibel = true
    },
    advanceclosed() {
      this.initadvance()
      // this.listParas.IsAdvancedSearch = 0
    },
    // 搜索合约
    serchcontractList() {
      this.listParas.PageIndex = 1
      this.getContractList()
    },
    // 终端选择
    sendremoteMethod(query) {
      this.selectsendloading = true
      this.CustomerCompanyList = []
      if (query && query.length > 0) {
        this.allCustomerCompanyList.forEach((item) => {
          if (this.CustomerCompanyList && this.CustomerCompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.CustomerCompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allCustomerCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.CustomerCompanyList.push(this.allCustomerCompanyList[i])
          }
        } else {
          this.CustomerCompanyList = this.allCustomerCompanyList
        }
      }
      this.selectsendloading = false
    },
    // 供应商选择
    sellremoteMethod(query) {
      this.selectsellloading = true
      this.SaleCompanyList = []
      if (query && query.length > 0) {
        this.allSaleCompanyList.forEach((item) => {
          if (this.SaleCompanyList && this.SaleCompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.SaleCompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allSaleCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.SaleCompanyList.push(this.allSaleCompanyList[i])
          }
        } else {
          this.SaleCompanyList = this.allSaleCompanyList
        }
      }
      this.selectsellloading = false
    },
    // 甲方选择
    buyremoteMethod(query) {
      this.selectBuyloading = true
      this.BuyCompanyList = []
      if (query && query.length > 0) {
        this.allbuyCompanyList.forEach((item) => {
          if (this.BuyCompanyList && this.BuyCompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.BuyCompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allbuyCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.BuyCompanyList.push(this.allbuyCompanyList[i])
          }
        } else {
          this.BuyCompanyList = this.allbuyCompanyList
        }
      }
      this.selectBuyloading = false
    },
    // 合约禁用启用
    EnableProduct(_IDX, type) {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.UpdateContractStatus({ IDX: _IDX }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 查看合约附件
    getContractFilesList(_idx) {
      const _this = this
      _this.contractIdforfile = _idx
      // if (!_this.checkedContract || _this.checkedContract.length < 1) {
      //   _this.$message('请选择要查看附件的合约')
      //   return false
      // }
      // if (_this.checkedContract.length > 1) {
      //   _this.$message('查看附件的合约只能选择一条')
      //   return false
      // }
      _this.fullscreenLoading = true
      $contractreq.GetContractFileList({ ContractID: _idx }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.contractFilesVisibel = true
          _this.contractFileList = res.Message
        }
      })
    },
    // 合约选中数据
    contracthandleSelectionChange(val) {
      this.checkedContract = []
      val.forEach((ele) => {
        this.checkedContract.push(ele.IDX)
      })
      console.log(this.checkedContract)
    },
    // 获取人员信息
    async  getUserInfo() {
      const _this = this
      _this.fullscreenLoading = true
      const res = await $userRequest.GetUserInfo()
      if (res.RetCode === '0') {
        _this.userInfo = res.Message
        _this.isCanCreate = false
      }
    },
    // 获取供应商数据
    getSellCompanyList() {
      const _this = this
      // _this.fullscreenLoading = true
      _this.sellCompanyListParas.CompanyType = 1
      $contractreq.GetSellCompanyList(_this.sellCompanyListParas).then(res => {
        // _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.allSaleCompanyList = res.Message
          // _this.SaleCompanyList = res.Message
        }
      })
    },
    // 获取买方数据
    getBuyCompanyListfromdata() {
      const _this = this
      // _this.fullscreenLoading = true
      _this.sellCompanyListParas.CompanyType = 0
      $contractreq.GetSellCompanyList(_this.sellCompanyListParas).then(res => {
        // _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.allbuyCompanyList = res.Message
          _this.allCustomerCompanyList = res.Message
        }
      })
    },
    // _type 1：all 0 只是终端
    getBuyCompanyList(_type) {
      const _this = this
      // _this.fullscreenLoading = true
      // _this.sellCompanyListParas.CompanyType = 0
      $contractreq.GetSellCompanyList(_this.sellCompanyListParas).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          if (_type === 1) {
            _this.allbuyCompanyList = res.Message
            // _this.BuyCompanyList = res.Message
            // _this.CustomerCompanyList = res.Message
            _this.allCustomerCompanyList = res.Message
          } else if (_type === 0) {
            // _this.CustomerCompanyList = res.Message
            _this.allCustomerCompanyList = res.Message
          }
        }
      })
    },
    // 新增合约
    oprenCreateContract() {
      const _this = this
      // 初始化数据
      _this.contractInfo = {
        Name: '',
        BuyCompanyID: null,
        SellCompanyID: null,
        SendCompanyID: null,
        DeadLine: '',
        UserName: '',
        NickName: '',
        ContractType: null, // 合约类型 // 0 销售， 1采购
        Operater: null
      }
      _this.SaleCompanyList = []
      _this.BuyCompanyList = []
      _this.CustomerCompanyList = []
      // 初始化新建数据 合约类型 -1-全部；1-作为买方的合约(采购；2-作为卖方的合约（销售
      if (_this.contractType === 2) {
        _this.isEditSell = true
        _this.isEditBuy = false
        // 获取终端以及客户
        // _this.getBuyCompanyList(1)
        _this.contractInfo.ContractType = 0 // 0 销售， 1采购
        // 处理供应商数据
        this.contractInfo.BuyCompanyID = null
        _this.SaleCompanyList.push({ id: _this.userInfo.CompanyID, text: _this.userInfo.CompanyName })
        _this.contractInfo.SellCompanyID = _this.userInfo.CompanyID
      } else if (_this.contractType === 1) {
        _this.isEditSell = false
        _this.isEditBuy = true
        // _this.getSellCompanyList()
        // _this.getBuyCompanyList(0)
        _this.contractInfo.ContractType = 1
        // 处理买方
        this.contractInfo.SellCompanyID = null
        _this.BuyCompanyList.push({ id: _this.userInfo.CompanyID, text: _this.userInfo.CompanyName })
        _this.contractInfo.BuyCompanyID = _this.userInfo.CompanyID
      }
      this.createcontractVisibel = true
    },
    // 验证创建合约
    confirmCreateContract() {
      const _this = this
      // 先校验终端 与买方是否与供应商一致
      if (_this.contractType === 2) {
        if (_this.contractInfo.SellCompanyID === _this.contractInfo.BuyCompanyID || _this.contractInfo.SellCompanyID === _this.contractInfo.CustomerCompanyID) {
          _this.$message('买方或者终端不能与供应商一致')
          return false
        }
      } else {
        if (_this.contractInfo.BuyCompanyID === _this.contractInfo.SellCompanyID) {
          _this.$message('买方不能与供应商一致')
          return false
        }
      }
      // 验证表单信息
      _this.$refs['createContract'].validate((valid) => {
        if (valid) {
          _this.$confirm('确认创建合约?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.CreateContract()
          }).catch(() => {
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    // 创建合约
    CreateContract() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.SetSalesContractSave(_this.contractInfo).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          // _this.getContractList()
          // 弹出 让用户去添加物料 还是返回列表的验证框
          _this.$confirm('新增合约成功', '提示', {
            confirmButtonText: '添加合约物料',
            cancelButtonText: '返回合约列表',
            type: 'success'
          }).then(() => {
            _this.createcontractVisibel = false
            _this.$router.push({ name: 'contractDetails1', params: { idx: res.Message }})
          }).catch(() => {
            _this.getContractList()
            _this.createcontractVisibel = false
          })
          // _this.$message('操作成功')
        } else {
          _this.$message('操作失败:' + res.RetMsg)
        }
      })
    },
    // ================================================= 选择人员 begin  =================================================
    // ================================================= 选择人员 begin ==================================================
    selectUseractiveNamehandleClick(val) {
      console.log(val)
    },
    // 初始化选择人员数据
    // _type 1：列表选择业务员 2 创建合约选择业务员 3 合约高级搜索选择人员
    oprenSelectContractUser(_type) {
      const _this = this
      _this.selUserType = _type
      if (_type === 1) {
        if (!_this.checkedContract || _this.checkedContract.length < 1) {
          _this.$message('请先选择要更换业务员的合约')
          return false
        }
      }
      _this.checkedUserInfo = null
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.fullscreenLoading = true
      _this.connuserlist = []
      $userRequest.SelectUserQueryListOfDept().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
      })
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 选中人员
    checkeduser(ischecked, val, item) {
      const _this = this
      if (ischecked) {
        if (_this.checkedUserInfo) {
          item.ischecked = !ischecked
          _this.$message('只能选择一个人员！')
          return false
        }
        if (item.children) {
          item.ischecked = !ischecked
          _this.$message('只能选择一个人员！')
          return false
        } else {
          _this.checkedUserInfo = item
          _this.checkedContractOptionUserID = item.IDX
          _this.checkedContractOptionUserName = item.UserName
          _this.checkedContractOptionUserNickName = item.GroupName
        }
      } else {
        _this.checkedUserInfo = null
        _this.checkedContractOptionUserID = null
        _this.checkedContractOptionUserName = null
        _this.checkedContractOptionUserNickName = null
      }
    },
    // 确认选中人员 _type 1：列表选择业务员 2 创建合约选择业务员
    doconnuser() {
      const _this = this
      if (!_this.checkedUserInfo) {
        _this.$message('请选中人员！')
        return false
      }
      switch (_this.selUserType) {
        case 1:
          _this.dealContractListOperater()
          break
        case 2:
          _this.dealCreateContractOperater()
          break
        case 3:
          _this.dealaddvanceUser()
          break
        default: return
      }
      // const _this = this
    },
    // 合约高级搜索人员
    dealaddvanceUser() {
      const _this = this
      if (_this.checkedContractOptionUserID && _this.checkedContractOptionUserID > 0) {
        _this.listParas.CreateUserID = _this.checkedContractOptionUserID
        _this.listParas.CreateUserName = _this.checkedContractOptionUserNickName
        _this.seletUserVisible = false
      } else {
        _this.$message('请选择人员再确定！')
      }
    },
    // 合约列表选择业务员
    dealContractListOperater() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.SaveSalesMan({
        UserName: _this.checkedUserInfo.UserName,
        ContactIDS: _this.checkedContract.join(','),
        SalesName: _this.checkedUserInfo.GroupName,
        PermissionGroupID: null,
        permissionGroupName: null
      }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractList()
          _this.$message('操作成功')
          _this.seletUserVisible = false
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 新建合约选择业务员
    dealCreateContractOperater() {
      const _this = this
      if (_this.checkedContractOptionUserName) {
        _this.contractInfo.UserName = _this.checkedContractOptionUserName
        _this.contractInfo.NickName = _this.checkedContractOptionUserNickName
        _this.seletUserVisible = false
      } else {
        _this.$message('请选择人员再确定！')
      }
    },
    // ================================================= 选择人员 end  =================================================
    // ================================================= 选择人员 end ==================================================
    // 合约状态 筛选
    filterTag(value, row) {
      return row.Status === value
    },
    getInfo(index) {
      switch (index) {
        case 1:
          return { info: '已启用', type: 'primary' }
        case 2:
          return { info: '已禁用', type: 'success' }
        case 3:
          return { info: '待审核', type: 'info' }
        case 4:
          return { info: '已驳回', type: 'info' }
        default:
          return { info: '暂无', type: 'warning' }
      }
    },
    // 打开高级搜索框
    OpenAdvanSearch() {
    },
    // 改变当前页
    contractlisthandleCurrentChange(val) {
      this.listParas.PageIndex = val
      this.getContractList()
    },
    // 查询列表信息
    getContractList() {
      const _this = this
      _this.fullscreenLoading = true
      _this.listParas.contractType = _this.contractType
      $contractreq.GetSalesContractList(_this.listParas).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.tableData = res.Message
          _this.tableTotalSize = res.Total
          _this.AdvancedsearchVisibel = false
        } else {
          _this.$message('获取合约列表失败：' + res.RetMsg)
        }
      })
    },
    // 导出合约明细
    exportData() {
    }
  } // methods ends
}
export default salesContract
