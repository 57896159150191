<template>
  <div class="">
    <PageHeader :border="false" :title="titletext" center />
    <div v-loading.fullscreen.lock="fullscreenLoading" class="p-lr-20">
      <div class="searchView  flex a-center m-bottom-10 j-between">
        <div v-if="$minCommon.checkMeau('xshyview', userBottons) || $minCommon.checkMeau('cghyview', userBottons)" class="flex" style="flex:1">
          <el-input v-model="listParas.KeyWords" size="medium" :placeholder="placeholdertext" class="rule-input-edit SearchBox_30">
            <template slot="prepend">
              <div class="cursor" @click="openadvanceContrac">
                <i class="el-icon-s-operation m-right-5" />高级搜索</div></template>
            <div slot="append" @click="serchcontractList">搜  索</div>
          </el-input>
          <div class="out p-left-10  flex a-center">
            <el-button v-if="contractType === 2 && $minCommon.checkMeau('xshychangeuser', userBottons)" class="ModelBttton-white-30" round size="mini" @click="oprenSelectContractUser(1)"><i class="el-icon-refresh m-right-5" />更换业务员</el-button>
            <el-button v-if="$minCommon.checkMeau('xshyadd', userBottons) || $minCommon.checkMeau('cghyadd', userBottons)" class="ModelBttton-white-30" round size="mini" type="primary" :disabled="isCanCreate" @click="oprenCreateContract"><i class="el-icon-document-copy m-right-5" />新建合约</el-button>
          </div>
        </div>
      </div>
      <el-table
        id="TableModel9"
        ref="multipleTable"
        v-loading="tableDataloading"
        height="calc(100vh - 315px)"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="IDX"
        border
        @selection-change="contracthandleSelectionChange"
      >
        <af-table-column
          fixed="left"
          type="selection"
          width="40"
        />
        <el-table-column
          v-if="contractType === 2"
          prop="IDX"
          label="销售合约编号"
          width="140"
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="$router.push('contractDetails/'+scope.row.IDX + '/' + 2)"
            >
              {{ scope.row.SAPContractID }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          v-if="contractType === 1"
          prop="IDX"
          label="采购合约编号"
        >
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="$router.push('contractDetails/'+scope.row.IDX + '/' + 1)">{{ scope.row.SAPContractID }}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="Name"
          label="合约名称"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="contractType === 2"
          prop="BuyCompanyName"
          label="买方"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="contractType === 1"
          prop="SellCompanyName"
          label="供应商"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="contractType === 2"
          prop="SendCompanyName"
          label="终端"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="contractType === 2"
          prop="SalesName"
          label="业务员"
          show-overflow-tooltip
        />
        <el-table-column
          label="状态"
          prop="Status"
          filter-placement="bottom-end"
          :filter-method="filterTag"
          :filters="[{text: '已启用', value: 1}, {text: '已禁用', value: 2}, {text: '待审核', value: 3}, {text: '已驳回', value: 4}]"
        >
          <template slot-scope="scope">
            <span :class="[2,3,4].indexOf(scope.row.Status) > -1 ? 'statusColor' : ''"> {{ getInfo(scope.row.Status).info }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="DeadLine"
          label="到期时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ $minCommon.setDateFormate(scope.row.DeadLine) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="WriteTime"
          label="创建时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="操作"
          width="50"
          align="center"
        >
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('xshyview', userBottons) || $minCommon.checkMeau('cghyview', userBottons)" class=" OperationButton">
                  <el-link :underline="false" @click="$router.push('contractDetails/'+scope.row.IDX+'/'+0)"><i class="el-icon-search p-right-10" />查看合约</el-link>
                </div>
                <div v-if="$minCommon.checkMeau('xshyattr', userBottons) || $minCommon.checkMeau('cghyviewattr', userBottons)" class=" OperationButton">
                  <el-link :underline="false" @click="getContractFilesList(scope.row.IDX)"><i class="el-icon-paperclip p-right-10" />查看附件</el-link>
                </div>
                <div v-if="scope.row.Status === 1 && ( $minCommon.checkMeau('xshydisable', userBottons) || $minCommon.checkMeau('cghydisable', userBottons))" class=" OperationButton">
                  <el-link :underline="false" @click="EnableProduct(scope.row.IDX, 4)"><i class="el-icon-document-delete p-right-10" />禁用合约</el-link>
                </div>
                <div v-if="scope.row.Status === 2 && ($minCommon.checkMeau('xshyenable', userBottons) || $minCommon.checkMeau('cghyenable', userBottons))" class=" OperationButton">
                  <el-link :underline="false" @click="EnableProduct(scope.row.IDX, 2)"><i class="el-icon-document-checked p-right-10" />启用合约</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :total="tableTotalSize"
          :current-page="listParas.PageIndex"
          :page-size="listParas.PageSize"
          :background="true"
          layout="prev, pager, next, jumper"
          @current-change="contractlisthandleCurrentChange"
        />
      </div>
    </div>
    <!-- 合约高级搜索 -->
    <el-dialog class="MaterialAdvanced" title="销售合约高级搜索" :visible.sync="AdvancedsearchVisibel" width="700px" :close-on-click-modal="false" @closed="advanceclosed">
      <el-button style="float:right; margin-right:20px" size="mini" plain @click="initadvance">清空搜索条件</el-button>
      <el-form label-position="right" label-width="100px" :inline="true" :model="listParas" size="mini" class="advanser p-lr-20">
        <el-form-item label="创建时间段">
          <el-date-picker
            v-model="listParas.StartTime"
            type="date"
            value-format="yyyy-MM-dd"
            style="width:calc((100% - 15px) / 2)"
          />
          -
          <el-date-picker
            v-model="listParas.EndTime"
            type="date"
            value-format="yyyy-MM-dd"
            style="width:calc((100% - 15px) / 2)"
          />
        </el-form-item>
        <el-form-item label="销售合约编号">
          <el-input
            v-model="listParas.ContractCode"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="合约名称">
          <el-input
            v-model="listParas.ContractName"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="买方">
          <el-select
            v-model="listParas.BuyCompanyID"
            filterable
            clearable
            remote
            reserve-keyword
            placeholder="请选择甲方名称"
            :remote-method="buyremoteMethod"
            :loading="selectBuyloading"
          >
            <el-option
              v-for="(item, index) in BuyCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="供应商">
          <el-select
            v-model="listParas.SellCompanyID"
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="请选择甲方名称"
            :remote-method="sellremoteMethod"
            :loading="selectsellloading"
          >
            <el-option
              v-for="(item, index) in SaleCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="contractType === 2" label="终端">
          <el-select
            v-model="listParas.CustomerCompanyID"
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="请选择甲方名称"
            :remote-method="sendremoteMethod"
            :loading="selectsendloading"
          >
            <el-option
              v-for="(item, index) in CustomerCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="创建人">
          <el-input v-model="listParas.CreateUserName" placeholder="请选择申请人" class="input-with-select">
            <el-button slot="append" :readonly="true" icon="el-icon-search" @click="oprenSelectContractUser(3)" />
          </el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="listParas.Status" placeholder="" filterable clearable>
            <el-option v-for="item in statusList" :key="item.value" :label="item.lable" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
      <div class="caozuo t-right p-right-20 p-top-20 t_line_s">
        <el-button @click="AdvancedsearchVisibel = false">关 闭</el-button>
        <el-button type="primary" @click="serchcontractList">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择业务员 -->
    <el-dialog class="DepartmentBox" title="批量替换业务员" center :visible.sync="seletUserVisible" width="400px" :close-on-click-modal="false">
      <div class="rolebody">
        <el-tabs v-model="selectUseractiveName" @tab-click="selectUseractiveNamehandleClick">
          <el-tab-pane label="成员" name="user" />
          <el-tab-pane v-if="isshow" label="部门" name="dept" />
          <el-tab-pane v-if="isshow" label="角色" name="role" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
        <div v-if="selectUseractiveName === 'dept'" class="selectuser">
          <el-tree
            ref="tree"
            :data="selectUserDeptData"
            node-key="value"
            highlight-current
          >
            <span slot-scope="{ node, data }" style="width:100%">
              <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
              <span class="deptnamespan">
                {{ node.label }}</span>
              <span class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkedDept(data.ischecked,data.value,data)" /></span>
            </span>
          </el-tree>
        </div>
        <div v-if="selectUseractiveName === 'role'" class="selectuser">
          <div v-for="(item, index) in selectUserroleData" :key="index" class="selectuserrole">
            <span class="deptnamespan">
              {{ item.name }}</span>
            <span class="deptcountspan"><el-checkbox v-model="item.ischecked" /></span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$refs.addUser.openaddUser()">新增人员</el-button>
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新增合约 -->
    <el-dialog class="DepartmentBox" :title="createContracttitle" :visible.sync="createcontractVisibel" width="500px" :close-on-click-modal="false">
      <el-form ref="createContract" :rules="createContractrules" label-position="right" label-width="80px" :model="contractInfo" size="medium" class="createContractDialog">
        <el-form-item label="合约名称" prop="Name">
          <el-input
            v-model="contractInfo.Name"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="买方" prop="BuyCompanyID">
          <!-- <el-select v-model="contractInfo.BuyCompanyID" placeholder="请选择甲方名称" filterable style="width:100%" :disabled="isEditBuy">
              <el-option  v-for="(item, index) in BuyCompanyList"  :key="index" :label="item.text" :value="item.id" />
            </el-select> -->
          <el-select
            v-model="contractInfo.BuyCompanyID"
            style="width:100%"
            :disabled="isEditBuy"
            filterable
            remote
            reserve-keyword
            placeholder="请选择甲方名称"
            :remote-method="buyremoteMethod"
            :loading="selectBuyloading"
          >
            <el-option
              v-for="(item, index) in BuyCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="供应商" prop="SellCompanyID">
          <!-- <el-select v-model="contractInfo.SellCompanyID" placeholder="" filterable style="width:100%" :disabled="isEditSell">
              <el-option  v-for="(item, index) in SaleCompanyList"  :key="index" :label="item.text" :value="item.id" />
            </el-select> -->
          <el-select
            v-model="contractInfo.SellCompanyID"
            style="width:100%"
            :disabled="isEditSell"
            filterable
            remote
            reserve-keyword
            placeholder=""
            :remote-method="sellremoteMethod"
            :loading="selectsellloading"
          >
            <el-option
              v-for="(item, index) in SaleCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="contractType === 2" label="终端" prop="CustomerCompanyID">
          <!-- <el-select v-model="contractInfo.CustomerCompanyID" placeholder="请选择送达方（终端客户）" filterable style="width:100%">
              <el-option  v-for="(item, index) in CustomerCompanyList"  :key="index" :label="item.text" :value="item.id" />
            </el-select> -->
          <el-select
            v-model="contractInfo.CustomerCompanyID"
            style="width:100%"
            filterable
            remote
            reserve-keyword
            placeholder=""
            :remote-method="sendremoteMethod"
            :loading="selectsendloading"
          >
            <el-option
              v-for="(item, index) in CustomerCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="失效时间" prop="DeadLine">
          <el-date-picker
            v-model="contractInfo.DeadLine"
            type="date"
            placeholder="请选择失效时间"
            value-format="yyyy-MM-dd"
            style="width:100%"
          />
        </el-form-item>
        <el-form-item v-if="contractType === 2" label="业务员" style="width:100%">
          <el-input v-model="contractInfo.NickName" placeholder="请选择业务员" class="input-with-select" :readonly="true">
            <el-button slot="append" icon="el-icon-search" @click="oprenSelectContractUser(2)" />
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="caozuo">
        <el-button @click="createcontractVisibel = false">关 闭</el-button>
        <el-button type="primary" @click="confirmCreateContract">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 合约附件 -->
    <el-dialog title="合约附件" :visible.sync="contractFilesVisibel" width="800px" :close-on-click-modal="false">
      <div class="p-lr-20">
        <contractFilsList :contract-file-data="contractFileList" :contract-id="contractIdforfile" />
      </div>
    </el-dialog>
    <addUser ref="addUser" />
  </div>
</template>
<script>
import salesContract from '@/minxin/contract/salesContract.js'
export default salesContract
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/main.scss';
@import '@/style/salesContract.scss';
</style>
<style lang='scss'>
.el-dialog--center .el-dialog__body {
  padding: 25px 25px 0px;
}
.salesContrac{
  position: relative;
  .allChilce{
      height: 25px;
      line-height: 25px;
      .active{
        color: #333;
      }
      .nomr{
         color: #ccc;
      }
  }
  .main_flex{
    // width: 100%;
    min-height: 400px;
    .left{
      width: 400px;
    }
    .right{
      flex: 1;
      min-width: 400px;
    }
  }
  .cropper-content {
    .cropper {
      //  min-width: 500px;
       width: 100%;
       height:  70vh;
    }
}
.absol{
  position: absolute;
  left: 0;
}
}
.test_pop_view{
  min-width: 60px;
}
</style>
